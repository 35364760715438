<template>
  <div>
    <ul class="space-y-3">
      <li v-for="option in options" :key="option.code">
        <radio-button
          :name="name"
          :option="option"
          :value="value == option.code"
          @input="handleInput"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RadioButtonGroup",
  components: {
    "radio-button": () => import("@/components/utils/RadioButton.vue"),
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event);
    },
  },
};
</script>
